import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import Delete from 'components/Util/Buttons/LightBackground/MediumDeleteSquareButton';

import Store from 'models/Form';

import styles from 'styles/Dashboard/Base';

@observer
export default class DangerZone extends Component {
  store = new Store();

  afterDelete = () => {
    window.location.reload();
  }

  render() {
    const { plan, organizationAccount } = this.props;
    const authenticityToken = document.querySelector('[name=csrf-token]').content;
    const action = `/home/account?authenticity_token=${encodeURIComponent(authenticityToken)}&ignore=`;

    return (
      <React.Fragment>
        <h5 className={classnames(styles.p2, styles.ColorError, 'fw-bold')}>Danger Zone</h5>
        <p className={classnames(styles.p2)}>Once deleted, you will no longer have access to Amply's API and SMTP relay. Please be certain.</p>

        <div style={{ lineHeight: '35px' }}>
          <Delete
            store={this.store}
            action={action}
            afterDelete={this.afterDelete}
            action={action}>Delete this organization</Delete>
        </div>
      </React.Fragment>
    );
  }
}
