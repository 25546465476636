import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from 'styles/Dashboard/Editor';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import InlineEditor from '../CKEditor/InlineEditor';

import { borderColor, borderWidth } from '../variables';

import DragHandle from './DragHandle';
import Node from './Node';
import Controls from './Controls';

@observer
export default class Col extends Node {
  static propTypes = {
    ...Node.propTypes
  }

  render() {
    const { node } = this.props;

    let classes = [];

    if (node.isSelected) {
      classes.push(styles.selectedNode);
    }

    const nodeStyles = {
      background: node.background,
      padding: node.padding,
      fontSize: node.fontSize,
      fontFamily: node.fontFamily,
      lineHeight: node.lineHeight,
      letterSpacing: node.letterSpacing,
      textIndent: node.textIndent,
      textAlign: node.textAlign,
    };

    if (!node.isSelected && node.isHovered) {
      nodeStyles.border = `${borderWidth} dashed ${borderColor}`;
    }
    else {
      nodeStyles.border = `${borderWidth} dashed transparent`;
    }

    const editorConfig = {
      htmlEmbed: {
        showPreviews: false,
      },
      image: {
        upload: {
          types: ['jpeg', 'png', 'gif', 'image/svg+xml'],
        },
        styles: [
            'alignLeft', 'alignCenter', 'alignRight'
        ],
        toolbar: [
          'imageStyle:alignLeft',
          'imageStyle:alignCenter',
          'imageStyle:alignRight',
          '|',
          'imageTextAlternative'
          ]
      },
      imageUpload: {
        uploadUrl: '/api/v1/settings/template_images',
        authorization: node.imageUploadAuth,
      },
      placeholder: 'Click to add content...',
      placeholderProps: {
        types: node.editor.placeholders
      }
    };

    const last = node === node.parent.children[node.parent.children.length - 1];

    return (
      <div
        className={classnames('col', `col-${node.width}`, classes)}
        style={nodeStyles}
        onMouseOver={this.onMouseOver}
        onMouseOut={this.onMouseOut}
        onClick={this.onClick}>

        <CKEditor
          config={editorConfig}
          editor={InlineEditor}
          data={node.content}
          onReady={(editor) => {
            node.ckeditor = editor;

          }}
          onBlur={(event, editor) => {
            node.setContent(editor.getData());
          }}
          />

        {!last && (
          <DragHandle node={node} />
        )}
      </div>
    );
  }
}
