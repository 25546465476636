import { observable, computed, action } from 'mobx';
import api from './API';

export default class TableAPI {
  @observable loaded        = false;
  @observable isFullyLoaded = false;
  @observable params        = { filters: {} };
  @observable results       = [];

  @observable path;
  @observable pageSize;

  constructor(params) {
    this.path = params?.path;
  }

  @action
  fetch() {
    this.loaded = false;
    this.isFullyLoaded = false;
    this.results.clear();

    const request = api.get(this.path, this.params);

    request.then(data => {
      this.pushUniqueData(data);
      this.loaded = true;
      this.pageSize = data.length;
    });

    return request;
  }

  fetchMore() {
    if (this.results.length === 0 || this.isFullyLoaded) {
      return false;
    }

    this.loaded = false;

    const request = api.get(this.path, { offset: this.results.length, ...this.params});

    request.then(data => {
      this.throttleFetching();
      this.pushUniqueData(data);

      if (data.length < this.pageSize) {
        this.isFullyLoaded = true;
      }
    });

    return request;
  }

  pushUniqueData(data) {
    const [firstElement] = data;

    if (firstElement?.id) {
      // ensure each element is unique
      const uniqueData = data?.filter(el => {
        const matches = this.results.filter(res => res.id === el.id);
        return matches.length === 0;
      }) || [];

      this.results.push(...uniqueData);
    }
    else {
      this.results.push(...data);
    }
  }

  throttleFetching() {
    const el = this;
    setTimeout(function() {
      el.loaded = true;
    }, 500);
  }
}
