import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import BackButton from 'components/Util/Buttons/LightBackground/BackButton';
import LoadableCard from 'components/Dashboard/LoadableCard';
import Attribute from 'components/Dashboard/Attribute';
import Delete from 'components/Util/Buttons/LightBackground/MediumDeleteSquareButton';

import Form from 'components/Util/Inputs/Form';
import InputM from 'components/Util/Inputs/InputM';
import SelectM from 'components/Util/Inputs/SelectM';
import CheckboxM from 'components/Util/Inputs/CheckboxM';
import Button from 'components/Util/Buttons/LightBackground/MediumPrimaryButton';

import Share from 'components/Util/Icons/Share';

import Store from 'models/Form';

import styles from 'styles/Dashboard/Base';

@observer
export default class Show extends Component {
  store = new Store();

  onSuccess = () => {
    window.location.reload();
  }

  afterDelete = () => {
    window.location = '/home/users';
  }

  onChange = (val) => {
    const { organizationAccount } = this.props;
    const store = this.store;


    if (!val[0]) {
      // no longer override, so let's set back to what was previously configured...
      const password = organizationAccount.password_authentication_enabled;
      const oauth = organizationAccount.oauth_authentication_enabled;
      const saml = organizationAccount.saml_authentication_enabled;

      this.store.get('password_authentication_enabled').setChecked(password);
      this.store.get('oauth_authentication_enabled').setChecked(oauth);
      this.store.get('saml_authentication_enabled').setChecked(saml);
    }
  }

  render() {
    const { organizationAccount } = this.props;
    const overrideAuthenticationStrategy = this.store.get('override_authentication_strategy')?.value;

    return (
      <div className='m-3'>
        <h3 className={classnames(styles.h3, 'fw-bold')}>
          <a href='/home/users' className='me-1'>
            <BackButton />
          </a>

          Update User

          <Delete action={`/api/v1/users/${organizationAccount.id}`} store={this.store} afterDelete={this.afterDelete} className='float-end mb-2'>Delete</Delete>
        </h3>

        <LoadableCard className='mb-2' loadState={organizationAccount.loaded}>
          <h5 className={classnames(styles.p2, 'fw-bold')}>User</h5>

          <Attribute icon={Share} name='Email Address' value={organizationAccount.email} className='mb-1' />
        </LoadableCard>

        <LoadableCard className='mb-2' loadState={organizationAccount.loaded}>
          <h5 className={classnames(styles.p2, 'fw-bold')}>Configuration</h5>

          <Form method='put' action={`/api/v1/users/${organizationAccount.id}`} onSuccess={this.onSuccess} store={this.store}>
            <label className={classnames(styles.Label)}>
              <p>Override Authentication Strategy</p>
              <SelectM
                defaultValue={organizationAccount.override_authentication_strategy}
                className={classnames(styles.InputWidth, 'mb-2')}
                onChange={this.onChange}
                name='override_authentication_strategy'
                placeholder='Override Strategy'
                store={this.store}>

                <option value={false}>No</option>
                <option value={true}>Yes</option>
              </SelectM>
            </label>

            <fieldset disabled={!overrideAuthenticationStrategy}>
              <label className={classnames(styles.Label)}>
                <p>Enabled Strategies</p>
                <p className={classnames(styles.InputDescription, 'mb-1')}>Change the way this user is authorized to sign into Amply. These strategies will only be enforced when <span className='fw-bold'>Override Authentication Strategy</span> is set to <span className='fw-bold'>Yes</span>.</p>

                <CheckboxM
                  className='me-1'
                  defaultChecked={organizationAccount.password_authentication_enabled}
                  name='password_authentication_enabled'
                  store={this.store}>

                  Password Authentication
                </CheckboxM>

                <CheckboxM
                  className='me-1'
                  defaultChecked={organizationAccount.oauth_authentication_enabled}
                  name='oauth_authentication_enabled'
                  store={this.store}>

                  OAuth Authentication
                </CheckboxM>

                <CheckboxM
                  className='me-1'
                  defaultChecked={organizationAccount.saml_authentication_enabled}
                  name='saml_authentication_enabled'
                  store={this.store}>

                  SAML Authentication
                </CheckboxM>
              </label>
            </fieldset>

            <div className='mt-2'>
              <Button>Update</Button>
              <InputM className={classnames(styles.ShowInputMessage, 'float-start')} type='hidden' name='id' value={organizationAccount.id} store={this.store} />
            </div>
          </Form>
        </LoadableCard>
      </div>
    );
  }
}
