import React, { Component } from 'react';
import { observer } from 'mobx-react';

import Content from 'components/Dashboard/Content';
import OrganizationAccountsSettingsShow from 'components/Dashboard/Users/Settings/Show';

import api from 'models/API';
import OrganizationAccount from 'models/OrganizationAccount';

@observer
export default class Show extends Component {
  constructor(props) {
    super(props);

    this.organizationAccount = new OrganizationAccount();

    api.get(`/api/v1/users/settings`).then(data => {
      this.organizationAccount.assign({ id: -1, settings: data });
    });
  }

  render() {
    return (
      <Content>
        <OrganizationAccountsSettingsShow organizationAccount={this.organizationAccount} />
      </Content>
    );
  }
}
