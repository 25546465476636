import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import Form from 'components/Util/Inputs/Form';
import SelectM from 'components/Util/Inputs/SelectM';
import InputM from 'components/Util/Inputs/InputM';
import Button from 'components/Util/Buttons/LightBackground/MediumPrimaryButton';

import LoadableCard from 'components/Dashboard/LoadableCard';
import Attribute from 'components/Dashboard/Attribute';

import Person from 'components/Util/Icons/Person';

import Store from 'models/Form';

import styles from 'styles/Dashboard/Base';

@observer
export default class Show extends Component {
  store = new Store;

  onSuccess = () => {
    window.location.reload();
  }

  render() {
    const { generalSettings } = this.props;

    return (
      <div className='m-3'>
        <h3 className={classnames(styles.h3, 'fw-bold')}>General Settings</h3>

        <LoadableCard className='mb-2' loadState={generalSettings.loaded}>
          <h5 className={classnames(styles.p2, 'fw-bold')}>General</h5>

          <Attribute icon={Person} name='SMTP Username' value={generalSettings.smtp_username} className='mb-1' />
        </LoadableCard>

        <LoadableCard loadState={generalSettings.loaded}>
          <h5 className={classnames(styles.p2, 'fw-bold')}>Global Configuration</h5>

          <Form method='put' action='/api/v1/settings/general/email' onSuccess={this.onSuccess} store={this.store}>
            <label className={classnames(styles.Label)}>
              <p>Clicktracking</p>
              <p className={classnames(styles.InputDescription, 'mb-1')}>Enable or disable clicktracking on your account. This can also be configured per verified domain or individual email.</p>
              <SelectM
                className={classnames(styles.InputWidth, 'mb-2')}
                defaultValue={generalSettings.clicktracking_enabled}
                name='clicktracking_enabled'
                store={this.store}>
                
                <option value={true}>Enabled</option>
                <option value={false}>Disabled</option>
              </SelectM>
            </label>

            <label className={classnames(styles.Label)}>
              <p>Email Activity Webhook</p>
              <p className={classnames(styles.InputDescription, 'mb-1')}>Enable or disable all email activity webhooks. This acts as a kill-switch if you need to temporarily disable all of your endpoints.</p>
              <SelectM
                className={classnames(styles.InputWidth, 'mb-2')}
                defaultValue={generalSettings.email_activity_webhook_alert}
                name='email_activity_webhook_alert'
                store={this.store}>

                <option value={true}>Enabled</option>
                <option value={false}>Disabled</option>
              </SelectM>
            </label>

            <div>
              <Button>Update</Button>
            </div>

          </Form>
        </LoadableCard>
      </div>
    );
  }
}
