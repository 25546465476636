import React, { Component } from 'react';
import { observer } from 'mobx-react';

import Content from 'components/Dashboard/Content';
import PlanShow from 'components/Dashboard/Account/Plan';

import api from 'models/API';

import OrganizationAccount from 'models/OrganizationAccount';
import PlanStore from 'models/Plan';

@observer
export default class Plan extends Component {
  constructor(props) {
    super(props);

    this.organizationAccount = new OrganizationAccount();
    this.organizationAccount.assign({ settings: {} });
    this.plan = new PlanStore();

    api.get('/home/account').then(data => {
      this.organizationAccount.assign(data);
    });

    api.get('/home/account/plan').then(data => {
      this.plan.assign(data);
    });
  }

  render() {
    return (
      <Content>
        <PlanShow plan={this.plan} organizationAccount={this.organizationAccount} />
      </Content>
    );
  }
}
