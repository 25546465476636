import React, { Component } from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

import Select from 'react-select';

import SelectDropdown from 'components/Util/Icons/SelectDropdown';

import styles from 'styles/Base';

@observer
export default class BaseSelect extends Component {
  select = observable.object({
    values: observable.array(),
    active: false
  });

  componentDidMount = () => {
    const values = this.select.values;
    let value;

    if (this.props.defaultValue !== undefined) {
      value = this.props.defaultValue;
    }

    if (this.props.value !== undefined) {
      value = this.props.value;
    }

    // insert value into mobx values
    if (Array.isArray(value)) {
      value.forEach(v => {
        values.push(v);
      });
    }
    else if (value !== undefined) {
      values.push(value);
    }
  }

  getFormElements = () => {
    const { store, multi } = this.props;
    const values = this.select.values;
    const formElements = [];

    const element = store.get(this.formatName());
    if (element) {
      formElements.push(element);
    }

    if (multi) {
      for (const index of values.keys()) {
        const element = store.get(this.formatName(index));
        if (element) {
          formElements.push(element);
        }
      }
    }

    return formElements;
  }

  hasErrors = () => {
    for (const element of this.getFormElements()) {
      if (element.hasError) {
        return true;
      }
    }

    return false;
  }

  getOptions = () => {
    return [this.props.children || []].flat(Infinity).map(option => {
      return { value: option.props.value, label: option.props.children, disabled: option.props.disabled }
    });
  }

  getSelectedOptions = () => {
    const values = this.select.values;

    return this.getOptions().filter(option => {
      return values.includes(option.value)
    });
  }

  onChange = (options, onChange) => {
    const values = this.select.values;

    if (Array.isArray(options)) {
      if (options.length === 0 || !options.every((option) => option.disabled)) {
        values.clear();
      }

      options.forEach(option => {
        if (!option.disabled) {
          values.push(option.value);
        }
      });
    }
    else {
      if (!options.disabled) {
        values.clear();
        values.push(options.value);
      }
    }

    if (typeof onChange !== 'undefined') {
      onChange(values);
    }
  }

  onFocus = (e) => {
    this.select.active = true;

    if (this.props.onFocus) {
      this.props.onFocus(e);
    }
  }

  onBlur = (e) => {
    this.select.active = false;

    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
  }

  formatName = (index=undefined) => {
    const { multi, name } = this.props;

    if (multi) {
      const formattedName = name.replace(/\[\]$/, '');
      const formattedIndex = isNaN(index) ? '' : `[${index}]`

      return `${formattedName}${formattedIndex}`;
    }

    return name;
  }

  renderInput = (values) => {
    const { multi, name, store } = this.props;
    const Input = this.props.inputComponent;
    const active = this.select.active;

    if (multi) {
      if (values.length > 0) {
        return (
          <React.Fragment>
            <Input name={this.formatName()} value='' store={store} type='hidden' />

            { values.map((value, index) =>
              <Input key={index} name={this.formatName(index)} value={values[index] || ''} store={store} type='hidden' />
            )}
          </React.Fragment>
        );
      }

      return (
        <Input name={`${this.formatName()}[]`} value='' store={store} type='hidden' />
      )
    }
    else {
      const value = values.length > 0 ? values[0] : ''
      return (
        <Input name={this.formatName()} value={value} store={store} type='hidden' />
      );
    }
  }

  render() {
    const { className, selectClassName, onChange, onFocus, onBlur, children, inputComponent, placeholder, multi, style, ...params } = this.props;
    const values = this.select.values;
    const klasses = [styles.Select];
    const s = Object.assign({}, style || {}, { zIndex: '1' });

    if (this.select.active) {
      klasses.push(styles.SelectActive);
    }

    if (this.hasErrors()) {
      klasses.push(styles.SelectError);
    }

    if (params.store?.disabled) {
      klasses.push(styles.SelectDisabled);
    }

    return (
      <div {...params} className={classnames('d-inline-block', 'position-relative', klasses, className)} style={s}>
        <Select
          isMulti={multi}
          isClearable={false}
          isSearchable={false}
          unstyled={true}
          placeholder={placeholder || ''}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onChange={(e) => this.onChange(e, onChange)}
          className={classnames(styles.SelectBase, selectClassName)}
          classNames={{
            container: (state) => state.isFocused && styles.SelectBaseFocus,
            control: (state) => styles.Control,
            indicatorsContainer: (state) => styles.Dropdown,
            menu: (state) => styles.Menu,
            multiValue: (state) => styles.MultiValue,
            placeholder: (state) => styles.Placeholder,
            singleValue: (state) => styles.SingleValue,
          }}
          components={{
            IndicatorSeparator: () => null,
            DropdownIndicator: () => <SelectDropdown />
            
          }}
          value={this.getSelectedOptions()}
          options={this.getOptions()} />

        {this.renderInput(values)}
      </div>
    );
  }
}
