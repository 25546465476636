import { types, getRoot, getParentOfType } from 'mobx-state-tree';
import { v4 as uuidv4 } from 'uuid';

import { Editor } from './Editor';

const GRID_COLUMNS = 12;

const Node = types
  .model('Node', {
    id: types.identifier,
    type: types.string,
    name: types.maybe(types.string),
    background: types.maybe(types.string),
    padding: types.maybe(types.string),
    customStyles: types.maybe(types.string),
    lineHeight: types.maybe(types.string),
    letterSpacing: types.maybe(types.string),
    fontFamily: types.maybe(types.string),
    fontSize: types.maybe(types.string),
    textIndent: types.maybe(types.string),
    textAlign: types.maybe(types.string),
    width: types.maybe(types.number),
    offset: types.maybe(types.number),
    content: types.maybe(types.string),
    parent: types.maybe(types.reference(types.late(() => Node))),
    children: types.array(types.late(() => Node)),
    toggled: types.optional(types.boolean, true),
  })
  .views(self => ({
    get editor() {
      return getParentOfType(self, Editor);
    },
    get imageUploadAuth() {
      return getRoot(self)?.dashboard?.tmpJwe;
    },
    get title() {
      if (self.name) {
        return `${self.name} (${self.type})`;
      } else {
        return self.type;
      }
    },
    get paddingTop() {
      if (!self.padding) {
        return;
      }

      return self.padding.split(' ')[0];
    },
    get paddingBottom() {
      if (!self.padding) {
        return;
      }

      return self.padding.split(' ')[2];
    },
    get paddingLeft() {
      if (!self.padding) {
        return;
      }

      return self.padding.split(' ')[3];
    },
    get paddingRight() {
      if (!self.padding) {
        return;
      }

      return self.padding.split(' ')[1];
    },
    get isHovered() {
      return self.editor.hoveredNode === self;
    },
    get isDescendantHovered() {
      return self.editor.hoveredNode === self || self.children.some(c => c.isDescendantHovered);
    },
    get isSelected() {
      return self.editor.selectedNode === self;
    },
    get isDescendantSelected() {
      return self.editor.selectedNode === self || self.children.some(c => c.isDescendantSelected);
    },
    get JSON() {
      return {
        name: self.name,
        type: self.type,
        background: self.background,
        customStyles: self.customStyles,
        padding: self.padding,
        lineHeight: self.lineHeight,
        letterSpacing: self.letterSpacing,
        fontFamily: self.fontFamily,
        fontSize: self.fontSize,
        textIndent: self.textIndent,
        textAlign: self.textAlign,
        width: self.width,
        content: self.content,
        children: self.children.map(c => c.JSON)
      };
    }
  }))
  .actions(self => ({
    reset() {
      self.load({});
    },
    afterCreate() {
      if (self.type === 'Document') {
        self.setBackground('#ffffff');
        self.setPadding('0px 0px 0px 0px');
      }

      if (self.type === 'Row') {
        self.setColumns(1);
      }

      if (self.type === 'Column') {
        self.setPadding('16px 16px 16px 16px');
        self.setFontSize('16px');
        self.setFontFamily('Arial, Helvetica, sans-serif');
        self.setLineHeight('32px');
        self.setLetterSpacing('0px');
        self.setTextIndent('0px');
        self.setTextAlign('left');
      }
    },
    load(json) {
      self.name = json.name || undefined;
      self.background = json.background || undefined;
      self.customStyles = json.customStyles || undefined;
      self.padding = json.padding || undefined;
      self.lineHeight = json.lineHeight || undefined;
      self.letterSpacing = json.letterSpacing || undefined;
      self.fontFamily = json.fontFamily || undefined;
      self.fontSize = json.fontSize || undefined;
      self.textIndent = json.textIndent || undefined;
      self.textAlign = json.textAlign || undefined;
      self.width = json.width || undefined;
      self.offset = json.offset || undefined;
      self.content = json.content || undefined;

      while (self.children.length > 0) {
        self.children[0].reset(); // remove all descendants
        self.removeChild(self.children[0]);
      }
      if (json.children) {
        json.children.forEach((child) => {
          self.addChild(child.type).load(child);
        });
      }
    },
    addChild(type, attrs = {}, insertBefore = null) {
      const child = Node.create(Object.assign(
        {},
        attrs,
        {
          id: `${self.id}/${uuidv4()}`,
          type: type || 'Row',
          parent: self,
        }
      ));

      if (insertBefore) {
        const index = self.children.indexOf(insertBefore);
        self.children.splice(index, 0, child);
      } else {
        self.children.push(child);
      }

      return child;
    },
    blur() {
      if (self.isHovered) {
        self.editor.clearHoveredNode();
      }
    },
    deselect() {
      if (self.isSelected) {
        self.editor.clearSelectedNode();
      }
    },
    hover() {
      self.editor.setHoveredNode(self);
    },
    removeChild(child) {
      child.parent = undefined;
      self.children = self.children.filter(n => n !== child);
    },
    resetColumnWidths() {
      const columns = self.children.length;
      const remainder = GRID_COLUMNS % columns;

      if (remainder === 0) {
        self.children.forEach(child => child.setWidth(GRID_COLUMNS / columns));
      } else {
        self.children.slice(0, -1).forEach(child => child.setWidth((GRID_COLUMNS - remainder) / columns));
        self.children[self.children.length - 1].setWidth(remainder);
      }
    },
    select() {
      self.editor.setSelectedNode(self);
    },
    setName(name) {
      self.name = name;
    },
    setBackground(color) {
      self.background = color;
    },
    setCustomStyles(customStyles) {
      self.customStyles = customStyles;
    },
    setPadding(padding) {
      self.padding = padding;
    },
    setFontFamily(fontFamily) {
      self.fontFamily = fontFamily;
    },
    setFontSize(fontSize) {
      self.fontSize = fontSize;
    },
    setLineHeight(lineHeight) {
      self.lineHeight = lineHeight;
    },
    setLetterSpacing(letterSpacing) {
      self.letterSpacing = letterSpacing;
    },
    setTextIndent(textIndent) {
      self.textIndent = textIndent;
    },
    setTextAlign(textAlign) {
      self.textAlign = textAlign;
    },
    setColumns(columns) {
      while (self.children.length > columns) {
        self.removeChild(self.children[self.children.length - 1]);
      }

      while (self.children.length < columns) {
        self.addChild('Column');
      }

      self.resetColumnWidths();
    },
    setContent(content) {
      self.content = content;
    },
    setWidth(width) {
      self.width = width;
    },
    toggle() {
      self.toggled = !self.toggled;
    },
  }));

export default Node;
